<template>
  <div>
    <div style="display: flex; justify-content: space-between;">
      <v-btn class="blue__v-btn" @click="downloadExcel(true)">
        Выгрузка Excel общая
      </v-btn>
      <v-btn class="blue__v-btn" @click="downloadExcel(false)">
        Выгрузка Excel
      </v-btn>
      <v-btn class="blue__v-btn" style="margin: 0 0 10px 20px" @click="getPdfFile">
        Выгрузка Pdf
      </v-btn>
      <v-btn
          v-if="acceptMonActive"
          class="blue__v-btn" style="margin: 0 0 10px auto" @click="acceptMon">
        Согласовано МОН
      </v-btn>
      <v-btn
          v-if="acceptGovActive"
          class="blue__v-btn" style="margin: 0 0 10px auto" @click="acceptGov">
        Согласовано Правительством РФ
      </v-btn>
    </div>
    <wrapper-preview-category customClass="section-preview_lk">
      <grid-table-full
          :data-rows="getOrganizedDataForMon"
          :headers="tableHeaders.massive"
          :headers-for-rows="tableHeaders.massive"
          :loading-data="tableData.loading"
          use-only-per-pages-default
          :user-per-pages-default="50"
          :disable-pagination-selector="false"
          name-table=""
          :error="errorMainRequest"
          key-name="subId"
          max-table-height="750"
          :num-cols="tableHeaders.countCells"
          :specific-calculate-rows-per-page="propsMethodForTable()"
          :data-fields-for-search="['sprFoName','currentOrgFounderName','totalRequestAmount', 'sprOrgFounderName', 'sprOrgName', 'sprSpecName', 'sprSpecCode']"
          ref="rtop"
      >
        <template #nameTable>
          <v-btn @click="filterDialog = !filterDialog" class="white__v-btn">
            <v-icon color="primary" size="25">mdi-filter-outline</v-icon>
            Фильтр
          </v-btn>
        </template>
        <template #sprSpecName="{ row }">
          <span>{{ `${row.sprSpecCode} - ${row.sprSpecName} (${row.sprFoName})` }}</span>
        </template>
        <template #totalRequestAmountSum="{ row }">
          <span style="width:100%;" :class="{ overflowColor: row.totalRequestAmountSum > row.numPlaces }">
            {{ row.totalRequestAmountSum }}
          </span>
        </template>
        <template #totalRequestAmount="{ row }">
          <span style="width:100%;" :class="{ overflowColor: row.totalRequestAmount > row.numPlaces }">
            {{ row.totalRequestAmount }}
          </span>
        </template>
        <!--        -->
        <template #kcpVal="{ row }">
          <span style="width:100%;">{{ kcpVal(row, row.item) }}</span>
        </template>
        <template #sprOrgFounderName="{ row }">
          <div>{{ row.sprOrgFounderName ? row.sprOrgFounderName : row.sprRegionName }}</div>
        </template>
        <template #subject="{ row }">
          <v-btn icon @click="openListSubjectDialog(row)">
            <v-icon color="primary" size="21">mdi-information</v-icon>
          </v-btn>
        </template>
        <template #actions="{ row }">

          <div class="d-flex align-center justify-center flex-row">
            <v-btn :disabled="row.founderStatus === 'ACCEPTED_GOV' || row.founderStatusRegion === 'ACCEPTED_GOV'"
                   icon @click="editDialogOpen(row)">
              <v-icon color="primary" size="24">mdi-pencil</v-icon>
            </v-btn>
            <v-btn :disabled="row.founderStatus === 'ACCEPTED_GOV' || row.founderStatusRegion === 'ACCEPTED_GOV'"
                   icon @click="deleteTableRow(row)">
              <v-icon color="primary" size="24">mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </grid-table-full>
      <v-dialog
          v-model="dialog"
          width="1300"
      >
        <v-card>
          <v-card-title>
            Изменение заявки респондента
          </v-card-title>
          <v-text-field
              v-model="tableRow.val"
              label="Количество детализированных КЦП"
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="tableRow.zakazchik"
              label="Заказчик"
              type="text"
          ></v-text-field>
          <v-text-field
              v-model="tableRow.zakazchikInn"
              label='ИНН (не обязательно)'
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="tableRow.zakazchikKpp"
              label='КПП (не обязательно)'
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="tableRow.orgTrud"
              label="Организация трудоустройства"
              type="text"
          ></v-text-field>
          <v-autocomplete
              v-model="tableRow.regionId"
              :items="regionsArray"
              label="Субъект трудоустройства"
              multiple
          ></v-autocomplete>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="dialog-btn" @click="editTableRow">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--      <v-dialog-->
      <!--        v-model="quotaOverflow"-->
      <!--        width="570"-->
      <!--      >-->
      <!--        <v-card>-->
      <!--          <v-card-title>-->
      <!--            Присутствуют заявки с превышением квоты приёма-->
      <!--          </v-card-title>-->
      <!--        </v-card>-->
      <!--      </v-dialog>-->
      <v-dialog
          v-model="loading"
          width="335"
      >
        <v-card>
          <v-card-title>
            Загрузка данных, подождите
          </v-card-title>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="filterDialog"
          width="800"
      >
        <v-card>
          <v-card-title>
            Фильтр
          </v-card-title>
          <v-autocomplete
              v-model="filter.totalRequestAmount"
              :items="overflowFilterArray"
              label="Состояние заявки"
              clearable
              no-data-text="нет таких строк"
              multiple
          ></v-autocomplete>
          <v-autocomplete
              v-model="filter.orgName"
              :items="orgNameFilterArray"
              label="Образовательная организация"
              clearable
              no-data-text="нет таких строк"
              multiple
          ></v-autocomplete>
          <v-autocomplete
              v-model="filter.specName"
              :items="specNameFilterArray"
              label="Направление обучения"
              clearable
              no-data-text="нет таких строк"
              multiple
          ></v-autocomplete>
          <v-autocomplete
              v-model="filter.founderName"
              :items="founderNameFilterArray"
              label="Респондент"
              clearable
              no-data-text="нет таких строк"
              multiple
          ></v-autocomplete>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="white__v-btn" @click="clearFilter">Сбросить</v-btn>
            <v-btn class="ml-2 blue__v-btn" @click="filterFunc">Применить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </wrapper-preview-category>
    <v-dialog max-width="600" v-model="showListRegionsDialog">
      <v-card>
        <v-card-title>{{ titleListRegionsDialog }}</v-card-title>
        <v-card-text>
          <div v-for="item in dataListRegionsDialog" :key="item.regionId">{{ item.regionName }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import request from "@/services/request";
import WrapperPreviewCategory from "@/components/GridTable/dataFolder/WrapperPreviewCategory";
import AdminQuotaTableHeaders from "@/components/GridTable/dataFolder/AdminQuotaTableHeaders";
import axios from "axios";
import GridTableFull from "@/components/GridTableFromKcp/GridTableFull";
// import {moduleDataGatherAllForMon, sprRegionGetAll} from "@/mockedData/adminQuota";

export default {
  name: "AdminQuotaTable",
  components: {GridTableFull, WrapperPreviewCategory},
  data: () => ({
    tableHeaders: [],
    dialog: false,
    filterDialog: false,
    innerArray: null,
    regionSelectorArray: [],
    tableRow: {
      parentId: null,
      id: null,
      val: 0,
      zakazchik: null,
      zakazchikInn: null,
      zakazchikKpp: null,
      orgTrud: null,
      regionId: null,
    },
    tableData: {
      loading: false,
      change: {value: false, id: null},
      error: null,
      editRow: null,
      data: [],
    },
    // quotaOverflow: false,
    loading: false,
    allDataForMon: [],
    organizedDataForMon: [],
    organizedDataForMonConst: [],
    acceptMonActive: false,
    acceptGovActive: false,
    errorMainRequest: false,
    // filterFunc founderNameFilterArray  specNameFilterArray orgNameFilterArray
    filter: {
      totalRequestAmount: [],
      orgName: [],
      specName: [],
      founderName: [],
    },
    filterType: null,
    orgNameFilterArray: [],
    specNameFilterArray: [],
    founderNameFilterArray: [],
    /*listRegionsForDialog*/
    showListRegionsDialog: false,
    dataListRegionsDialog: [],
    titleListRegionsDialog: "",

    overflowFilterArray: [
      {text: 'Переполненные заявки', value: 'overflow'},
      {text: 'Нормальные заявки', value: 'normal'},
    ]
  }),
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles'
    }),
    regionsArray() {
      return this.regionSelectorArray
    },
    getOrganizedDataForMon() {
      this.organizedDataForMon.forEach((el, index, self) => {
        const rowsd = self.filter(e => e.sprOrgId === el.sprOrgId && e.sprSpecId === el.sprSpecId && e.sprFoId === el.sprFoId)
        const hasMainRow = rowsd.find(e => e.mainRow2);
        const countRas = rowsd.length
        if (el.mainRow2 === null) {
          self[index].mainRow2 = hasMainRow === undefined;
        }
        self[index].countRas = countRas
        self[index].totalRequestAmountSum = rowsd.reduce((sum, ec) => {
          return sum + (ec.mainRow ? ec.totalRequestAmount : 0)
        }, 0)
      })
      return this.organizedDataForMon
    }
  },

  created() {
    this.getAllForMon();
    this.getAllSprRegion();
    this.tableHeaders = AdminQuotaTableHeaders(this)
  },
  methods: {
    openListSubjectDialog(row) {
      this.titleListRegionsDialog = `Субъекты трудоустройства`;//${row.item.zakazchik} - ${row.item.val}
      this.dataListRegionsDialog = row.item.sprRegions;
      this.showListRegionsDialog = true;
    },
    regionsNamesList(item) {
      let str = '';
      for (var r of item) {
        if (str !== '') {
          str += "; " + r.regionName;
        } else {
          str += r.regionName;
        }
      }
      return str;
    },

    kcpVal(row, item) {
      if (!item)
        return
      if (row.founderStatus === 'ACCEPTED_MON' || row.founderStatusRegion === 'ACCEPTED_MON' ||
          row.founderStatus === 'ACCEPTED_GOV' || row.founderStatusRegion === 'ACCEPTED_GOV') {
        if (item.valStage3 !== item.val) {
          return `${item.valStage3} (${item.val})`
        }
        return `${item.valStage3}`
      }
      if (row.founderStatus === 'SENT' || row.founderStatusRegion === 'SENT') {
        if (item.valStage2 !== item.val) {
          return `${item.valStage2} (${item.val})`
        }
        return `${item.valStage2}`
      }
      return item.val
    },
    propsMethodForTable() {
      return (row, index, perPage) => {
        if (index < perPage) return true
        return index >= perPage && (!row?.mainRow || !row?.mainRow2);
      }
    },
    async getAllForMon() {
      this.loading = true;
      try {
        const res = /*{status: 200, data: moduleDataGatherAllForMon}*/ await request({
          endpoint: `/api/moduledatagather/getAllForMon`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          console.log('getAllForMon успех');
          this.loading = false;
          this.organizedDataForMonConst = res.data.reduce((acc, el) => {
            this.acceptMonActive = el.founderStatus === "SENT" || el.founderStatusRegion === "SENT"
            this.acceptGovActive = el.founderStatus === 'ACCEPTED_MON' || el.founderStatusRegion === 'ACCEPTED_MON'
            if (this.orgNameFilterArray.find(v => v === el.sprOrgName) === undefined) this.orgNameFilterArray.push(el.sprOrgName)
            if (this.specNameFilterArray.find(v => v === el.sprSpecCode + " - " + el.sprSpecName) === undefined) this.specNameFilterArray.push(el.sprSpecCode + " - " + el.sprSpecName)
            if (this.founderNameFilterArray.find(v => v === el.sprOrgFounderName ? el.sprOrgFounderName : el.sprRegionName) === undefined)
              this.founderNameFilterArray.push(el.sprOrgFounderName ? el.sprOrgFounderName : el.sprRegionName)
            const totalRequestAmount = this.totalRequestAmount(el, el.array)
            acc.push(
                {
                  ...el,
                  subId: `${el.id}.${el.array[0]?.id}`,
                  // requestAmount: this.requestAmount(el, el.array[0]),
                  item: el.array[0],
                  mainRow: true,
                  mainRow2: null,
                  countRas: 1,
                  totalRequestAmountSum: 0,
                  totalRequestAmount: totalRequestAmount
                },
                ...el.array.reduce((acc1, item, i) => {
                      if (i !== 0)
                        acc1.push({
                          ...el,
                          totalRequestAmountSum: 0,
                          totalRequestAmount: totalRequestAmount,
                          subId: `${el.id}.${item.id}`,
                          mainRow: false,
                          mainRow2: null,
                          countRas: 1,
                          item: item,
                          // requestAmount: this.requestAmount(el, item),
                        })
                      return acc1
                    }
                    , [])
            );

            return acc
          }, []);
          this.organizedDataForMon = this.organizedDataForMonConst
        }
      } catch (e) {
        this.loading = false;
        this.errorMainRequest = true;
        console.error(e);
      }
      this.loading = false;
    },

    async getAllSprRegion() {
      try {
        const res =/* {status: 200, data: sprRegionGetAll}*/await request({
          endpoint: `/api/spr/region/getAll`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].regionName && res.data[i].regionId) {
              this.regionSelectorArray.push({
                text: res.data[i].regionName,
                value: res.data[i].regionId
              })
            }
          }
          console.log('getAllSprRegion успех');
          this.sprRegionData = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },

    totalRequestAmount(row, item) {
      let sum = 0;
      for (let i = 0; i < item.length; i++) {
        if (row.founderStatus === 'ACCEPTED_MON' || row.founderStatusRegion === 'ACCEPTED_MON' ||
            row.founderStatus === 'ACCEPTED_GOV' || row.founderStatusRegion === 'ACCEPTED_GOV') {
          sum = sum + item[i].valStage3;
        } else if (row.founderStatus === 'SENT' || row.founderStatusRegion === 'SENT') {
          sum = sum + item[i].valStage2;
        } else {
          sum = sum + item[i].val;
        }
      }
      return sum
    },

    editDialogOpen(row) {
      const {id, array, item} = row;
      this.dialog = true;
      this.innerArray = array;
      this.tableRow.parentId = id;
      this.tableRow.id = item.id;
      this.tableRow.row = row;
      this.tableRow.zakazchik = item.zakazchik;
      this.tableRow.orgTrud = item.orgTrud;
      this.tableRow.item = item;
      this.tableRow.zakazchikInn = item.zakazchikInn;
      this.tableRow.zakazchikKpp = item.zakazchikKpp;
      if (item.founderStatus === 'ACCEPTED_MON' || item.founderStatusRegion === 'ACCEPTED_MON' ||
          item.founderStatus === 'ACCEPTED_GOV' || item.founderStatusRegion === 'ACCEPTED_GOV') {
        this.tableRow.val = item.valStage3;
      } else if (item.founderStatus === 'SENT' || item.founderStatusRegion === 'SENT') {
        this.tableRow.val = item.valStage2;
      } else {
        this.tableRow.val = item.val;
      }
      const pickedRegionsArray = [];
      for (let i = 0; i < item.sprRegions.length; i++) {
        pickedRegionsArray.push(item.sprRegions[i].regionId);
      }
      this.tableRow.regionId = pickedRegionsArray;
    },

    async editTableRow() {
      try {
        const res = await request({
          endpoint: `/api/moduledatagather/setpart`,
          method: 'post',
          token: `${this.getAuthData.token}`,
          body: {
            ...this.tableRow.item,
            "parentId": this.tableRow.parentId,
            "id": this.tableRow.id,
            "val": Number.parseInt(this.tableRow.val),
            "zakazchik": this.tableRow.zakazchik,
            "zakazchikInn": this.tableRow.zakazchikInn,
            "zakazchikKpp": this.tableRow.zakazchikKpp,
            "orgTrud": this.tableRow.orgTrud,
            "regionId": this.tableRow.regionId
          }
        });
        if (res.status === 200 && res.data) {
          // await this.getAllForMon();
          // await this.filterFunc();
          const mainRowIndex = this.organizedDataForMon.findIndex(el => el.id === this.tableRow.parentId);
          const mainRow = this.organizedDataForMon[mainRowIndex];
          const subId = `${this.tableRow.parentId}.${this.tableRow.id}`
          const newArr = mainRow.array.map(el => {
            if (el.id === this.tableRow.id) {
              const mapRes = {...el, ...res.data,}
              return {...mapRes}
            } else return el
          })
          // const requestAmount = this.requestAmount(mainRow, res.data)
          const mapRes = {...res.data}
          if (mainRow.subId === subId) {
            mainRow.item = mapRes;
            // mainRow.requestAmount = requestAmount;
          } else {
            for (let i = mainRowIndex; i < (mainRowIndex + mainRow.array.length); i++) {
              if (this.organizedDataForMon[i].subId === subId) {
                this.organizedDataForMon[i].item = mapRes
                // this.organizedDataForMon[i].requestAmount = requestAmount;
              }
            }
          }
          mainRow.array = newArr
          mainRow.totalRequestAmount = this.totalRequestAmount(mainRow, newArr)
          console.log('editTableRow успех');
          this.dialog = false;
          this.tableRow.parentId = null;
          this.tableRow.id = null;
          this.tableRow.val = null;
          this.tableRow.zakazchik = null;
          this.tableRow.zakazchikInn = null;
          this.tableRow.zakazchikKpp = null;
          this.tableRow.row = null;
          this.tableRow.item = null;
          this.tableRow.orgTrud = null;
          this.tableRow.regionId = null;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async deleteTableRow(row) {
      const {id, subId, item} = row;
      try {
        const res = await request({
          endpoint: `/api/moduledatagather/deletepart?collectionId=${item.id}`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          // if (row.array.length === 1) {
          //   this.organizedDataForMon = this.organizedDataForMon.filter(el => el.id !== row.id);
          //   this.organizedDataForMonConst = this.organizedDataForMonConst.filter(el => el.id !== row.id);
          // } else {
          //   let x = this.organizedDataForMon.find(el => el.id === row.id);
          //   this.organizedDataForMon = x.array.filter(el => el.id !== item);
          // }
          // await this.getAllForMon();
          // await this.filterFunc();
          const indexToRowRemove = this.organizedDataForMon.findIndex(el => el.subId === subId)
          const indexMainRow = this.organizedDataForMon.findIndex(el => el.id === id)
          const mainItem = this.organizedDataForMon[indexMainRow]
          const newArr = mainItem.array.filter(el => el.id !== item.id)
          console.log(indexToRowRemove, indexMainRow, mainItem, newArr)
          // return
          // eslint-disable-next-line
          mainItem.array = newArr
          for (let i = indexMainRow; i < (newArr.length + 1); i++)
            this.organizedDataForMon[i].array = newArr;
          if (subId === mainItem.subId) {
            if (mainItem.array.length === 0)
              this.organizedDataForMon[indexToRowRemove].item = null;
            else {
              const indSlice = indexToRowRemove + 1;
              if (!this.organizedDataForMon[indSlice])
                return;
              ['item', 'subId'].forEach(
                  el => this.organizedDataForMon[indexToRowRemove][el] = this.organizedDataForMon[indSlice][el]
              )
              this.organizedDataForMon.splice(indSlice, 1)
            }
          } else
            this.organizedDataForMon.splice(indexToRowRemove, 1)

          console.log('deleteTableRow успех');
        }
      } catch (e) {
        console.error(e);
      }
    },

    // выгрузка Excel /////////////////////////////////////////////
    async downloadExcel(old) {
      let uri = `/api/moduledatagather/report/mon`;
      if ((this.organizedDataForMon[0].founderStatus === 'ACCEPTED_MON' || this.organizedDataForMon[0].founderStatus === 'ACCEPTED_GOV' ||
          this.organizedDataForMon[0].founderStatusRegion === 'ACCEPTED_MON' || this.organizedDataForMon[0].founderStatusRegion === 'ACCEPTED_GOV') && !old) {
        uri = `/api/reports/govTable`
      }
      const responseAxios = await axios.get(uri, {
        responseType: 'blob',
        token: `${this.getAuthData.token}`
      })
      this.saveByteArray("MON.xlsx", responseAxios.data)
    },

    async getPdfFile() {
      let uri = `/api/moduledatagather/report/mon/pdf`;
      if (this.organizedDataForMon[0].founderStatus === 'ACCEPTED_MON' || this.organizedDataForMon[0].founderStatus === 'ACCEPTED_GOV' ||
          this.organizedDataForMon[0].founderStatusRegion === 'ACCEPTED_MON' || this.organizedDataForMon[0].founderStatusRegion === 'ACCEPTED_GOV') {
        uri = `/api/reports/govTable/pdf`
      }
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        token: `${this.getAuthData.token}`
      })
      this.saveByteArray("MON.pdf", responseAxios.data)
    },

    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], {type: "application/xlsx"});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    },

    // quotaOverflowCheck() {
    //   for (let a = 0; a < this.organizedDataForMon.length; a++) {
    //     if (this.totalRequestAmount(this.organizedDataForMon[a].array) > this.organizedDataForMon[a].numPlaces) {
    //       this.quotaOverflow = true;
    //       return
    //     }
    //   }
    // },

    async acceptMon() {
      // this.quotaOverflowCheck();

      // if (!this.quotaOverflow) {
      try {
        const res = await request({
          endpoint: `/api/moduledatagather/mon/acceptMon`,
          method: 'get',
          token: `${this.getAuthData.token}`,
        });
        if (res.status === 200 && res.data) {
          await this.getAllForMon();
          console.log('acceptMon успех');
        }
      } catch (e) {
        console.log(e);
      }
      // } else {
      //   this.quotaOverflow = true
      // }
    },

    async acceptGov() {
      // this.quotaOverflowCheck();

      // if (!this.quotaOverflow) {
      try {
        const res = await request({
          endpoint: `/api/moduledatagather/mon/acceptGov`,
          method: 'get',
          token: `${this.getAuthData.token}`,
        });
        if (res.status === 200 && res.data) {
          await this.getAllForMon();
          console.log('acceptGov успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    clearFilter() {
      Object.keys(this.filter).forEach(key => this.filter[key] = [])
      this.organizedDataForMon = this.organizedDataForMonConst;
    },
    filterFunc() {
      this.organizedDataForMon = this.organizedDataForMonConst.filter((el, index, array) => {
            array[index].mainRow2 = null;
            return (this.filter.orgName.length === 0 || this.filter.orgName.includes(el.sprOrgName)) &&
            (this.filter.specName.length === 0 || this.filter.specName.includes(el.sprSpecCode + " - " + el.sprSpecName)) &&
            (this.filter.founderName.length === 0 || this.filter.founderName.includes(el.sprOrgFounderName)) &&
            (this.filter.totalRequestAmount.length === 0
                || (el.totalRequestAmountSum > el.numPlaces && this.filter.totalRequestAmount.includes('overflow'))
                || (el.totalRequestAmountSum <= el.numPlaces && this.filter.totalRequestAmount.includes('normal')))
          }
      )
      this.filterDialog = false;
    },
  }
}
</script>

<style lang="sass">
.yellow-background
  background-color: #fff3cd
  color: #D4990A

.section-preview__header
  font-size: 24px
  font-weight: bold
  margin: 16px 0
  color: #334D6E

.dialog-btn
  .v-btn__content
    padding: 10px 5px

.v-autocomplete
  padding: 0 24px

.v-text-field
  padding: 0 24px

.overflowColor
  background: orange
  padding: 5px 15px
</style>
